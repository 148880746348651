import React from 'react';
import Typography from '@mui/material/Typography'
import { Link } from 'gatsby-theme-material-ui'
import PropTypes from 'prop-types'

function Breadcrumbs({ links }) {
  return (
    <div style={{ 
      display: 'flex', 
      flexWrap: 'wrap', 
      alignItems: 'center', 
      marginBottom: 16,
      opacity: 0.58
    }}>
      {
        links.map(({ name, path, isDisabled }, i) => {
          return (
            <React.Fragment key={path}>
              {isDisabled 
                ? <Typography color="textPrimary" component="p" style={{ fontSize: 12, lineHeight: '30px' }}>{name}</Typography>
                : <Link to={path} style={{ fontSize: 12, lineHeight: '30px' }}>{name}</Link>
              }
              {
                i < links.length - 1 && 
                <Typography 
                color="textPrimary" 
                component="span" 
                style={{
                  display: 'inline-block', 
                  marginLeft: 6, 
                  marginRight: 6,
                  fontSize: 12
                }}>
                  {` ⟶ `}
                </Typography>
              }
            </React.Fragment>
          )
        })
      }
    </div>
  );
}

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool
  })).isRequired
};

export default Breadcrumbs;