import React from 'react';
import { styled } from '@mui/material/styles';
import SEO from "../components/seo"
import { Typography, Grid, Container, useTheme, useMediaQuery, Box } from '@mui/material'
import { Button } from 'gatsby-theme-material-ui'
import { StaticImage } from "gatsby-plugin-image"
import TitleHeader from '@components/TitleHeader'
import Breadcrumbs from '@components/Breadcrumbs';

const PREFIX = 'InclineConveyorsPage';

const classes = {
  container: `${PREFIX}-container`,
  header: `${PREFIX}-header`,
  textContainer: `${PREFIX}-textContainer`,
  displayGrid: `${PREFIX}-displayGrid`,
  imgWrapper: `${PREFIX}-imgWrapper`,
  img: `${PREFIX}-img`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.container}`]: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },

  [`& .${classes.header}`]: {
    color: theme.palette.text.accent,
    fontSize: 30,
    textTransform: 'uppercase',
    fontFamily: 'Prompt, sans-serif',
  },

  [`& .${classes.textContainer}`]: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(4), 
      marginRight: theme.spacing(4), 
    },
  },

  [`& .${classes.displayGrid}`]: {
    display: 'grid'
  },

  [`& .${classes.imgWrapper}`]: {
    display: 'grid',
    position: 'relative',
    overflow: 'hidden',
  },

  [`& .${classes.img}`]: {
    cursor: 'pointer',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.03)',
    }
  }
}));

const Gallery = () => {

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} className={classes.displayGrid} >
        <div className={classes.imgWrapper}>
          <StaticImage
            src="../images/incline-conveyors/incline_conveyor_1.jpg"
            alt="incline conveyors scheme"
            placeholder="blurred"
            layout="fullWidth"
            className={classes.img}
          />
        </div>
      </Grid>
      <Grid item xs={6} className={classes.displayGrid}>
        <div className={classes.imgWrapper}>
          <StaticImage
            src="../images/incline-conveyors/incline_conveyor_2.jpg"
            alt="incline conveyors scheme"
            placeholder="blurred"
            layout="fullWidth"
            className={classes.img} 
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.imgWrapper}>
          <StaticImage
            src="../images/incline-conveyors/incline_conveyor_4.jpg"
            alt="incline conveyors scheme"
            placeholder="blurred"
            layout="fullWidth"
            className={classes.img}
          />
        </div>
      </Grid>
      <Grid item xs={6} className={classes.displayGrid}>
        <div className={classes.imgWrapper}>
          <StaticImage
            src="../images/incline-conveyors/incline_conveyor_3.jpg"
            alt="incline conveyors scheme"
            placeholder="blurred"
            layout="fullWidth"
            className={classes.img}
          />
        </div>
      </Grid>
      <Grid item xs={6} className={classes.displayGrid}>
        <div className={classes.imgWrapper}>
          <StaticImage
            src="../images/incline-conveyors/incline_conveyor_7.jpg"
            alt="incline conveyors scheme"
            placeholder="blurred"
            layout="fullWidth"
            className={classes.img}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.imgWrapper}>
          <StaticImage
            src="../images/incline-conveyors/incline_conveyor_scheme_3.jpg"
            alt="incline conveyors scheme"
            placeholder="blurred"
            layout="fullWidth"
            className={classes.img}
          />
        </div>
      </Grid>
    </Grid>
  );
};

function InclineConveyorsPage() {

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    (<Root>
      <SEO 
        title="Incline Conveyors" 
        description="Best deals on Incline Conveyors. Incline Conveyors for your needs at a price that fits your budget. Shop now and save big on conveyors!" 
      />
      <Container maxWidth="md" disableGutters>
        <div className={classes.container}>
          <Breadcrumbs links={
            [
              { name: 'Home', path: `/` },
              { name: `Incline Conveyors`, path: `/incline-conveyors`, isDisabled: true }
            ]
          } />
          <TitleHeader
            title={'Incline conveyors'}
            contentVerticalPosition="bottom"
            additionalContent={
              <Button to="/all-price-lists#0" variant="contained" color="secondary">
                View price list
              </Button>
            }
          />
          <Box container spacing={4} component="div" className={classes.list}>
            <Typography component='p' color='textPrimary' paragraph>
              Our Standard Drive over units come with a 5’x 5’ dump, Bi-pass Hopper 2-10’ conveyor section, I- Elbow and all other components to complete conveyor. We offer AR Lined or Standard Conveyor Sections.
            </Typography>
            <Typography component='p' color='textPrimary' paragraph>
              We can custom design most any incline system to meet your needs.
            </Typography>
          </Box>
          <Gallery />
          <Box display={'flex'} justifyContent={'flex-end'} my={2}>
            <Button 
            to={'/'}
            color="primary"
            variant="contained"
            >
              Go back to the main page
            </Button>
          </Box>
          <Typography component='p' color='textPrimary' paragraph>
            Incline conveyors are an indispensable part of modern agriculture and industry. At Farm Equipment Plus, we offer a wide range of incline conveyors that provide reliable and efficient transport for your crops and other materials over significant distances and at various angles of inclination.
          </Typography>
          <Typography component='h2' variant='h3' color='textPrimary' paragraph>
            Benefits of Using Incline Conveyors:
          </Typography>

          <ol>
            <li>
              <Typography component='p' color='textPrimary'>
                Efficiency and Time Savings: Incline conveyors allow for quick and efficient movement of materials to high elevations and tight situations.
              </Typography>
            </li>
            <li>
              <Typography component='p' color='textPrimary'>
                Versatility: Our range includes various models and configurations of incline conveyors that can be adapted to your specific needs and operating conditions.
              </Typography>
              </li>
            <li>
              <Typography component='p' color='textPrimary'>
                Reliability and Durability: We offer only quality conveyors, made from durable materials, ensuring their long service life and minimal maintenance costs.
              </Typography>
            </li>
          </ol>

          <Typography component='h2' variant='h3' color='textPrimary' paragraph>
            Applications of Incline Conveyors:          
          </Typography>
          <Typography component='p' color='textPrimary' paragraph>
            Our company's incline conveyors are used in various industries, including:
          </Typography>

          <ul>
            <li>
              <Typography component='p' color='textPrimary'>
                Agriculture: for transporting grain, fertilizer, polymers,salt, and other products and material.
              </Typography>
            </li>
            <li>
              <Typography component='p' color='textPrimary'>
                Manufacturing: for moving finished products and raw materials at different stages of production.
              </Typography>
              </li>
          </ul>

          <Typography component='h2' variant='h3' color='textPrimary' paragraph>
            North America Sales:
          </Typography>
          <Typography component='p' color='textPrimary' paragraph>
            We take pride in offering our equipment throughout North America and Abroad. No matter where your operation is located, we can provide you with the equipment you need for your business. Our shipping service deliveries literally worldwide.
          </Typography>
          <Typography component='h2' variant='h3' color='textPrimary' paragraph>
            Why Choose Us:
          </Typography>
          <Typography component='p' color='textPrimary' paragraph>
            Farm Equipment Plus is a reliable partner for agricultural and industrial enterprises. Our incline conveyors combine high quality, reliability, and competitive prices, making us the preferred choice for your business.
          </Typography>
          <Typography component='p' color='textPrimary' paragraph>
            We stock various models for quick delivery.
          </Typography>
          <Typography component='p' color='textPrimary' paragraph>
            Contact us today to learn more about our range of incline conveyors and choose the optimal solution for your needs. Get in touch with us right now!
          </Typography>
        </div>
      </Container>
    </Root>)
  );
}

export default InclineConveyorsPage;